import React, { useState, useEffect, createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PubSub from "pubsub-js";
import Header from "./Header";
import Footer from "./Footer";
import { SunriseSunsetWrapper } from "../contexts/SunriseSunsetContext";
import { FiltersWrapper } from "../contexts/FiltersWrapper";
// import Cursor from "./ui/Cursor";
import IntroKeywords from "./IntroKeywords";

const WrapperContext = createContext({});

const query = graphql`
  query {
    settings: sanitySettings {
      siteName
      introKeywords
      message404
    }
  }
`;

const Layout = ({ children, pageContext, location }) => {
  const { settings } = useStaticQuery(query);
  // const settings = {};
  const { template } = pageContext;
  // console.log(pageContext);
  const [visited, setVisited] = useState(0);
  // console.log(visited);

  useEffect(() => {
    _format();
    window.addEventListener("resize", _format);
    // _detectSafari();
    return () => {
      window.removeEventListener("resize", _format);
    };
  }, []);

  // const _detectSafari = () => {
  //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  //   console.log("isSafari", isSafari);
  //   if (isSafari) document.documentElement.classList.add("is-safari");
  // };

  useEffect(() => {
    const token = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      setVisited(visited + 1);
    });
    return () => {
      PubSub.unsubscribe(token);
    };
  }, [visited]);

  const _format = () => {
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    );

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    );

    const footerBounding = document
      .querySelector("footer")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--footer-height",
      footerBounding.height + "px"
    );
  };

  return (
    <WrapperContext.Provider value={{ settings, location }}>
      <SunriseSunsetWrapper>
        <FiltersWrapper>
          {/* <div>
            <Cursor color='#7afb87' size='18' />
          </div> */}
          {/* {template === "lieux" && visited < 2 && (
            <IntroKeywords keywords={settings.introKeywords} />
          )} */}
          <div id='page'>
            <Header />
            <main>{children}</main>
            <Footer />
          </div>
        </FiltersWrapper>
      </SunriseSunsetWrapper>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, Layout };
