import React, { createContext, useContext, useState, useEffect } from "react";
// const SunriseSunsetContext = createContext([{}, () => {}]);
import PubSub from "pubsub-js";
import SunCalc from "suncalc";
const SunriseSunsetContext = createContext({});

export const SunriseSunsetWrapper = ({ children }) => {
  // const END_POINT =
  //   "https://api.sunrise-sunset.org/json?lat=48.8746933,2.3479528&formatted=0";
  const LAT = 48.8746933;
  const LNG = 2.3479528;
  const [ephemeride, setEphemeride] = useState();
  const [sunrise, setSunrise] = useState();
  const [sunset, setSunset] = useState();
  const [index, setIndex] = useState(0);
  // const moments = ["Matin", "Après-midi", "Soir", "Nuit"]; //midi
  const moments = ["Matin", "Midi", "Après-midi", "Soir", "Nuit"]; //midi

  useEffect(() => {
    const result = SunCalc.getTimes(new Date(), LAT, LNG);
    console.log(result);
    setEphemeride(result);

    // fetch(END_POINT, {
    //   method: "GET",
    //   // body: raw,
    //   redirect: "follow",
    // })
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // console.log(result);
    //     setEphemeride(result);
    //   })
    //   .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    // if (ephemeride && ephemeride.results) {
    //   setSunrise(new Date(ephemeride.results.sunrise));
    //   setSunset(new Date(ephemeride.results.sunset));
    // }
    if (ephemeride) {
      setSunrise(new Date(ephemeride.sunrise));
      setSunset(new Date(ephemeride.sunset));
    }
  }, [ephemeride]);

  useEffect(() => {
    if (sunrise && sunset) {
      _setMoment();
      setInterval(_setMoment, 1000 * 60 * 60);
      setTimeout(() => {
        // console.log("is ready");
        document.documentElement.classList.add("is-ready");
        PubSub.publish("SITE_IS_READY");
      }, 1000);
    }
  }, [sunrise, sunset]);

  const _setMoment = () => {
    // console.log(sunrise);
    const now = new Date();
    const h = now.getHours();
    // const h = 22;

    // const sunriseH = sunrise.getHours();
    const sunsetH = sunset.getHours() || 0;
    // console.log(sunriseH, h, sunsetH);
    let _index = 0;
    //"Matin", "Midi", "Après-midi", "Soir", "Nuit"
    if (h < 12) {
      //matin
      if (h > 5) {
        _index = 0;
      } else {
        _index = 4;
      }
    } else {
      //midi/aprem/soir/nuit
      if (h < sunsetH) {
        if (h < 14) _index = 1;
        else _index = 2;
      } else {
        if (h <= sunsetH + 4) {
          _index = 3;
        } else {
          _index = 4;
        }
      }
    }

    setIndex(_index);
    // setIndex(4);
  };

  useEffect(() => {
    document.querySelector("#page").className = `moment-${index}`;
    document.documentElement.dataset.moment = `moment-${index}`;
  }, [index]);

  const next = () => {
    const nextMomentIndex = (index + 1) % moments.length;
    setIndex(nextMomentIndex);
  };

  const getColor = () => {
    switch (index) {
      case 0:
        return { background: "#fcf5f9", foreground: "#000" };
      case 1:
        return { background: "#f4f4f4", foreground: "#000" };
      case 2:
        return { background: "#b0c1bd", foreground: "#000" };
      case 3:
        return { background: "#073551", foreground: "#fff" };
      case 4:
        return { background: "#001635", foreground: "#fff" };
    }
  };
  // console.log(index, getColor());
  const moment = moments[index];

  return (
    <SunriseSunsetContext.Provider value={{ index, moments, next, getColor }}>
      {children}
    </SunriseSunsetContext.Provider>
  );
};

export default function useSunriseSunset() {
  return useContext(SunriseSunsetContext);
}
