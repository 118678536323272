import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import gsap from "gsap";
import PubSub from "pubsub-js";
import styled from "styled-components";

const Wrapper = styled.section`
  background: var(--bg);
  color: var(--color-primary);
  // .word:first-child
  letter-spacing: 0.1em;
`;

const IntroKeywords = ({ keywords }) => {
  const [hide, setHide] = useState(false);
  // const [count, setCount] = useState(0);
  const ref = useRef();
  const countRef = useRef(0);
  const [word, setWord] = useState(keywords[countRef.current]);
  // const timeoutRef = useRef();
  const intervalRef = useRef();
  const interval = 1000;

  useEffect(() => {
    ref.current.addEventListener("mousemove", _remove);
    ref.current.addEventListener("click", _remove);

    //le temps sunrise-sunset-context se lance
    const token = PubSub.subscribe("SITE_IS_READY", (e) => {
      intervalRef.current = setInterval(_tick, interval);
    });

    return () => {
      clearInterval(intervalRef.current);
      PubSub.unsubscribe(token);
      if (ref.current) {
        ref.current.addEventListener("mousemove", _remove);
        ref.current.addEventListener("click", _remove);
      }
    };
  }, []);

  const _start = () => {};

  const _tick = () => {
    if (countRef.current < keywords.length - 1) {
      countRef.current = countRef.current + 1;
      setWord(keywords[countRef.current]);
    } else {
      _remove();
    }
  };

  const _remove = () => {
    clearInterval(intervalRef.current);
    if (!ref.current) {
      _clean();
    }
    if (ref.current) {
      gsap.to(ref.current, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          _clean();
        },
      });
    }
  };

  const _clean = () => {
    setHide(true);
    if (ref.current) {
      ref.current.removeEventListener("mousemove", _remove);
      ref.current.removeEventListener("click", _remove);
    }
  };

  return (
    <Wrapper
      ref={ref}
      className={clsx(
        "fixed top-0 left-0 w-screen h-screen z-50 bg-secondary",
        hide ? "hidden" : ""
      )}>
      <div className='h-full flex flex-col- justify-center items-center text-lg text-black word text-center'>
        {word}
      </div>
    </Wrapper>
  );
};

export default IntroKeywords;
