import React from "react";
// import { Link } from "gatsby";
import { _linkResolver } from "../core/utils";
// import LinkFade from "./ui/TransitionLinks/LinkFade";
import { Link } from "gatsby";

const Menu = ({ input }) => (
  <nav>
    <ul className='flex flex-col md:flex-row justify-center uppercase'>
      {/* <li className='px-md'>Lieux</li>
        <li className='px-md'>Info</li>
        <li className='px-md'>Filtres</li> */}
      {input.map((li, i) => (
        <li key={i} className='menu-item'>
          <Link
            to={`${_linkResolver(li.link)}`}
            className='py-sm md:py-0 md:px-md inline-block transition-transform'
            activeStyle={{ transform: "rotate(-12deg)" }}>
            {li.label}
          </Link>
        </li>
      ))}
      <li className='menu-item'>
        <Link
          to='/search'
          className='py-sm md:py-0 md:px-md inline-block transition-transform'
          activeStyle={{ transform: "rotate(-12deg)" }}>
          SEARCH
        </Link>
      </li>
    </ul>
  </nav>
);

export default Menu;
